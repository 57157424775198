export const option = {
  column: [
    {
      label: '字段名称',
      prop: 'fieldName'
    },
    {
      label: '对应变量',
      prop: 'variableName'
    },
    {
      label: '支持类型',
      prop: 'exportType',
      dicData: [
        {
          label: '无支持类型',
          value: 0
        },
        {
          label: '产品导出',
          value: 1
        },
        {
          label: '订单导出',
          value: 2
        },
        {
          label: '产品导出 + 订单导出',
          value: 3
        }
      ]
    },
    {
      label: '排序',
      prop: 'sortNumber'
    },
    {
      label: '状态',
      prop: 'isEnable',
      dicData: [
        {
          label: '禁用',
          value: 0
        },
        {
          label: '启用',
          value: 1
        }
      ]
    }
  ]
}
